<template>
  <div id="reservationContainer">
    <vs-breadcrumb class="mb-5">
      <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li><router-link to="/prostovoljci">Prostovoljci</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">{{volunteerId ? 'Urejanje' : 'Dodajanje'}} prostovoljca</li>
    </vs-breadcrumb>

    <VolunteerEdit :volunteerId="volunteerId"></VolunteerEdit>
  </div>
</template>

<script>
  import Vue from 'vue';
  import VolunteerEdit from "../../components/Sopotniki/volunteers/VolunteerEdit";


  export default {
    name: 'Volunteer',

    components: {
      VolunteerEdit
    },

    data() {
      return {
        volunteerId: this.$route.params.volunteerId ? parseInt(this.$route.params.volunteerId) : null,
      }
    },

    computed: {},

    mounted() {
      const _this = this;
      // _this.getVolunteer();
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.customerId = null;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      // getVolunteer() {
      //   const _this = this;
      //
      //   _this.$vs.loading();
      // },

    }
  }
</script>

